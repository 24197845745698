import { Box, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';
import { PrimitiveType } from '../../../data-models/field3.data-model';
import { FormFundData, fundDataFormSchema } from '../../../schemas/FundData.schema';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { FMT } from '../../../util/formatter-service';
import { schemaToFormFields } from '../../../util/schema-utils';
import { IFormField } from '../../../view-models/form.view-model';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderRow,
  StyledTableRow,
} from '../../CompanyProfiles/components/Table/StyledGridComponents';
import { fundDataSortedByDateDescStateFP } from '../state/FPState';
import { editableFundDataFields, editableFundDataFields2 } from './FundDataFormActions';

const historyFields = ['date', 'updatedAt', 'updatedBy'] as (keyof FormFundData)[];

function dataTypeToCellType(dataType: PrimitiveType): 'numeric' | 'text' {
  switch (dataType) {
    case 'number':
    case 'date':
      return 'numeric';
    default:
      return 'text';
  }
}

export function FundDataHistory({ fundId }: { fundId: number }) {
  const { showFundDataForm2 } = useFlags<MaggieFeatureFlags>();
  const fieldPaths = useMemo(() => {
    if (showFundDataForm2) {
      return historyFields.toSpliced(1, 0, ...editableFundDataFields2);
    } else {
      return historyFields.toSpliced(1, 0, ...editableFundDataFields);
    }
  }, [showFundDataForm2]);
  const fundData = useAtomValue(fundDataSortedByDateDescStateFP(fundId));
  const schema = fundDataFormSchema();
  const fieldMap = useMemo(() => {
    return schemaToFormFields(schema, fieldPaths).reduce((map, f) => {
      return map.set(f.key, f);
    }, new Map<string, IFormField<unknown>>());
  }, [fieldPaths, schema]);

  const rows = useMemo(() => {
    return fundData.map((data) => {
      return fieldPaths.reduce(
        (acc, key) => {
          const field = fieldMap.get(key)!;
          let value = data[key];
          if (key === 'updatedBy' && !value) {
            value = data['createdBy'];
          }
          const formatter = FMT.get().getFormatterForFormField(field);
          const formattedValue = formatter ? formatter(value) : String(value ?? '');
          acc[key] = {
            formattedValue,
            type: dataTypeToCellType(field.dataType ?? 'string'),
          };
          return acc;
        },
        {} as Record<keyof FormFundData, { formattedValue: string; type: 'numeric' | 'text' }>
      );
    });
  }, [fieldMap, fieldPaths, fundData]);

  const tableRows = useMemo(
    () =>
      rows.map((row, i) => (
        <StyledTableRow key={i}>
          {Object.keys(row).map((key) => {
            const field = row[key as keyof typeof row];
            if (key == 'updatedBy') {
              return (
                <StyledTableCell key={key} type={field.type}>
                  <Stack direction={'row'} alignItems={'center'} gap='0.25rem'>
                    <MUIAvatar nameParts={field.formattedValue.split(' ')} src={''} />
                    {field.formattedValue}
                  </Stack>
                </StyledTableCell>
              );
            }
            return (
              <StyledTableCell key={key} type={field.type}>
                {field.formattedValue}
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      )),
    [rows]
  );

  if (!fundData.length) return null;
  return (
    <Box mt='1rem'>
      <Typography variant='subtitle1'>History Log</Typography>
      <StyledTable
        style={{
          gridTemplateColumns: `repeat(${fieldPaths.length}, 1fr)`,
          marginTop: '0.5rem',
        }}
      >
        <StyledTableHeaderRow>
          {[...fieldMap.values()].map((c) => (
            <StyledTableCell key={c.label} type={'text'} header title={c.label}>
              {c.label}
            </StyledTableCell>
          ))}
        </StyledTableHeaderRow>
      </StyledTable>
      <StyledTable
        style={{
          gridTemplateColumns: `repeat(${fieldPaths.length}, 1fr)`,
          marginTop: '0.5rem',
          maxHeight: '24rem',
          overflowY: 'auto',
        }}
      >
        {tableRows}
      </StyledTable>
    </Box>
  );
}
